.active{
    color: var(--orange) !important;
    font-weight: 700 !important;
}
.nav-item a{
    cursor: pointer;
}
.fixTrue{
    position: fixed;
    background-color: var(--gray-placeholder);
    left: 0px;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 15px;

}
.fixFalse{
    position: static;
}