.pop-head-programe::after{
    content: "";
    position: absolute;
    top: -160px;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: var(--orange);
    z-index: -1;
  }
  .cards-programs > div span{
    width: 50px;
    height: 50px;
    background-color: var(--orange);
    position: absolute;
    top: -25px !important;
    right: -25px !important;
  }
  .circle-programe{
    width: 25px;
    height: 25px;
    background-color: var(--orange);
  }
  .head-subprograme{
    border-bottom: 4px solid white;
    display: inline-block;
  }
  /* Responsive */
@media only screen and (min-width: 992px) and (max-width: 1200px){
 
  .head-subprograme{
    font-size: 18px;
  }
  .cards-programs > div{
    height: 105px;
  }

}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .head-subprograme{
    font-size: 16px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px){
  .head-subprograme{
    font-size: 16px;
  }
}
@media only screen and (max-width: 549px){
  .head-subprograme{
    font-size: 16px;
  }
}