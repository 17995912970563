.head-plain{
    background-color: var(--orange);
  }
  .plain-icon{
    width: 100px;
    height: 100px;
    background-color: var(--white);
    border-bottom: 4px solid var(--orange);
    font-size: 50px;
    color:var(--orange)
  }
  .box-plain{
    min-height: 400px;
  }
  .plain-price{
    width: 120px;
    height: 80px;
    background-color: var(--orange);
    font-size: 40px;
    color: var(--white);
    top: 50px !important;
  }
  .subicon-plain{
    color: var(--orange);
  }
  .btn-plain{
    background-color: var(--orange);
  }
  .goBack{
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 50px;
    color: var(--orange);
    cursor: pointer;
  }
  /* Responsive */
@media only screen and (min-width: 992px) and (max-width: 1200px){
  .plain-price{
    width: 100px;
    height: 80px;
    font-size: 30px;
  }
  .name-plain{
    font-size: 22px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .plain-price{
    width: 80px;
    height: 60px;
    font-size: 25px;
  }
  .name-plain{
    font-size: 20px !important;
  }
  .list-plains > li > span{font-size: 18px !important;}
}
@media only screen and (min-width: 550px) and (max-width: 767px){
  .plain-price{
    width: 80px;
    height: 60px;
    font-size: 25px;
  }
  .name-plain{
    font-size: 20px !important;
  }
  .list-plains > li > span{font-size: 18px !important;}
}
@media only screen and (max-width: 549px){
  .box-plain{
    margin-bottom: 100px !important;
  }
}