.why-us img{
    border-top: 15px solid white;
    border-bottom: 15px solid white;
  }
  .choouse-us{
    background-color: var(--orange);
  }
  .choouse-check{
    color: var(--orange);
  }
  /* Responsive */
@media only screen and (min-width: 992px) and (max-width: 1200px){
  .head-choose{
    font-size: 30px;
  }
}

