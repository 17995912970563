/* hero */
.card-hero{
    height: 100vh;
  }
  .bg-blur{
      filter: blur(6px);
      background: linear-gradient(0deg, rgba(238,174,202,0) 0%, rgba(246,107,14,1) 50%, rgba(148,187,233,0) 100%);
  }
  .card-hero img{
    object-fit: cover !important;
  }
  /* main-home */
  .main-home{
    background-color: var(--gray-placeholder);
  }
  .navbar{
    z-index: 9999;
  }
  .hero-logo{
    font-size: 70px;
  }
  .name-club{
    color: var(--orange) !important;
    padding-bottom: 13px;
  }
  .name-club label{
    width: 100%;
    height: 2px;
    background-color: var(--orange);
    bottom: 0; 
    left: 0;
  }
  .hero-logo img{
    width: 150px;
  }
  .lets-start{
    background-color: var(--orange);
    position: absolute;
    right: -250px;
    bottom: 0;
    padding-left: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 250px;
    border-left: 4px solid #fff !important;
    cursor: pointer;
  }
/* Responsive */
@media only screen and (min-width: 992px) and (max-width: 1200px){
  .hero-logo{
    font-size: 50px;
  }
  .hero-logo img{
    width: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .hero-logo{
    font-size: 40px;
  }
  .hero-logo img{
    width: 100px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px){
  .background-hero-image .col{
    padding: 10px;
  } 
  .navbar-nav{
    background-color: var(--orange)!important;
  }
  .hero-logo{
    font-size: 35px;
  }
  .hero-logo img{
    width: 90px;
  }
}
@media only screen and (max-width: 549px){
  .background-hero-image .col{
    padding: 5px;
  }
  .navbar-nav{
    background-color: var(--orange)!important;
  }
  .hero-logo{
    font-size: 30px;
  }
  .hero-logo img{
    width: 80px;
  }
}